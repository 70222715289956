import React from 'react'
import { Suspense,lazy } from 'react';
import Part1 from './Part1/Part1';
const Appbar=lazy(()=> import("./Appbar/Appbar"))
const Part2=lazy(()=> import("./Part2/Part2"))
const Part3=lazy(()=> import("./Part3/Part3"))
const FAQ=lazy(()=> import("./FAQ/FAQ"))
function Main() {
    return (
        <div>
      <Suspense fallback={<div/>}>
      <Appbar/>
      </Suspense>
       <Part1/>
      <Suspense fallback={<div/>}>
      <Part2/>
      </Suspense>
    
      <Suspense fallback={<div/>}>
      <Part3/>
      </Suspense>

      <Suspense fallback={<div/>}>
      <FAQ/>
      </Suspense>
        </div>
    )
}

export default Main
